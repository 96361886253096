import React, { useContext, useEffect } from "react";
import {
  StyledCountBadge,
  StyledLeadItem,
} from "../../LeadListPage/LeadList/LeadItem/LeadItem";
import useSimplifiedFormItem from "./useSimplifiedFormItem";
import ItemProduct from "./ItemProduct/ItemProduct";
import ItemInfos from "./ItemInfos/ItemInfos";
import { Provider } from "jotai";
import OpenDetailButton from "../../LeadListPage/LeadList/LeadItem/OpenDetailButton/OpenDetailButton";
import TodoListView from "../../../common/TodoList/TodoListView/TodoListView";
import CommentListView from "../../../common/CommentList/CommentListView/CommentListView";
import { ItemContext } from "./ItemContext";
import ItemBottomPanel from "./ItemBottomPanel";
import TodoList from "../../../common/TodoList/TodoList";
import CommentList from "../../../common/CommentList/CommentList";
import SimplifiedFormWrapper from "../../SimplifiedFormDetailPage/SimplifiedFormWrapper";
import { SimplifiedFormListContext } from "../useSimplifiedFormList";
import ItemAssignTo from "./ItemAssignTo/ItemAssignTo";
import ItemCreatedAt from "./ItemCreatedAt/ItemCreatedAt";
import LeadStatus from "../LeadStatus/LeadStatus";
import useStatus from "../../LeadListPage/LeadList/LeadItem/LeadStatus/useStatus";
import { useLocation } from "react-router-dom";
import { PanelSectionTitleAbs } from "../../../common/layout/StyledComponents/StyledComponents";
import LeadAttachedDocument from "./LeadAttachedDocument/LeadAttachedDocument";
import tools from "../../../../helpers/tools";
const queryString = require("query-string");

const SimplifiedFormItem = ({ data }) => {
  const { actions, simplifiedFormItem, bottomPanelContent } =
    useSimplifiedFormItem();
  const { myProducers, myRole } = useContext(SimplifiedFormListContext);
  const { tasksByProduct, managers, contacts, onDeleteLead } =
    useContext(ItemContext);
  const { statusRef } = useStatus(simplifiedFormItem?.statut);
  const location = useLocation();
  const canIUploadQuotation = ["ADMIN", "MANAGER", "COLLABORATOR"].includes(
    myRole
  );

  useEffect(() => {
    actions.initItem(data);
  }, [data]);

  useEffect(() => {
    const parsed = queryString.parse(location.search, {
      arrayFormat: "bracket",
    });
    let tm = null;
    if (simplifiedFormItem?.id && parsed.leadId == simplifiedFormItem.id) {
      tm = setTimeout(() => {
        document
          .querySelector(`#formitem${simplifiedFormItem.id}`)
          .scrollIntoView({ behavior: "smooth" });
      }, 1000);
    }
    return () => clearTimeout(tm);
  }, [simplifiedFormItem?.id]);

  if (!simplifiedFormItem.id) return null;
  const canBeDeleted = !simplifiedFormItem.formData.selectedCompany;

  const dateDevisDemande = simplifiedFormItem.formData?.productOptions
    ?.date_demande_devis ? (
    <div
      className=" d-flex align-items-center"
      style={{ fontSize: 12, gap: 5 }}
    >
      <div
        className="badge badge-orange rounded-circle d-center"
        style={{
          width: "16.3px",
        }}
      >
        <i className="fa fa-exclamation text-white" />
      </div>
      demandé le{" "}
      {tools.formatDate(
        simplifiedFormItem.formData?.productOptions?.date_demande_devis,
        "dd/MM/yyyy HH:mm"
      )}
    </div>
  ) : null;

  return (
    <StyledLeadItem
      className="shadow-sm rounded mb-3 position-relative"
      id={`formitem${simplifiedFormItem.id}`}
      style={{
        borderLeft: `3px solid var(--${statusRef?.color})`,
        minHeight: 236,
      }}
    >
      <div className="row mx-0 bg-white pt-2  border-top border-right  rounded-top ">
        <div
          className="col-12 pb-2  d-flex align-items-start flex-wrap justify-content-between"
          style={{ gap: 5 }}
        >
          <div className="pt-1">
            <ItemAssignTo
              simplifiedFormItem={simplifiedFormItem}
            ></ItemAssignTo>
          </div>
          <ItemCreatedAt
            simplifiedFormItem={simplifiedFormItem}
          ></ItemCreatedAt>
        </div>

        <div
          className="lead-status-column col-12 col-md-1 d-center flex-md-column justify-content-md-between  align-items-center flex-row justify-content-between py-2"
          style={{
            background: `var(--${statusRef?.color})`,
            color: "white",
            borderTopRightRadius: 5,
            // borderBottomRightRadius: 10,
            boxShadow: "inset -2px 0px 6px -4px #000000",
          }}
        >
          <ItemProduct />
          <LeadStatus
            lead={simplifiedFormItem}
            onUpdateStatus={actions.onUpdateStatus}
          />
        </div>
        <div className="col-12 col-md-3 pb-3">
          <ItemInfos />
          {canBeDeleted && (
            <button
              onClick={() => onDeleteLead(simplifiedFormItem.id)}
              className="border-0 px-0  bg-transparent  d-flex align-items-center justify-content-between cursor-pointer text-danger"
              style={{ fontSize: 12 }}
            >
              <strong>Supprimer ce lead</strong>
            </button>
          )}
        </div>
        <div
          className="col-12 col-md-4 "
          style={
            {
              // borderLeft: "1px solid #f6f6f6",
              // borderRight: "1px solid #f6f6f6",
            }
          }
        >
          <Provider>
            <OpenDetailButton
              onClick={actions.openComments}
              title={
                <>
                  <StyledCountBadge
                    disabled={simplifiedFormItem.comments?.length === 0}
                  >
                    {simplifiedFormItem.comments?.length}
                  </StyledCountBadge>{" "}
                  Commentaires
                </>
              }
            />
            <CommentListView
              initComments={simplifiedFormItem.comments}
              onUpdateComments={actions.onUpdateComments}
              onUpdateComment={actions.onUpdateComment}
              onAddComment={actions.onAddComment}
              users={contacts}
            />
          </Provider>
        </div>
        <div className="col-12 col-md-4 position-relative mt-3 mt-md-0">
          <Provider>
            <OpenDetailButton
              onClick={actions.openTasks}
              title={
                <>
                  <StyledCountBadge
                    disabled={simplifiedFormItem.tasks?.length === 0}
                  >
                    {simplifiedFormItem.tasks?.length}
                  </StyledCountBadge>{" "}
                  Tâches
                </>
              }
            />
            <TodoListView
              initTodos={simplifiedFormItem.tasks}
              tasksByProduct={tasksByProduct}
              onUpdateTodos={actions.onUpdateTasks}
              onUpdateTodo={actions.onUpdateTask}
              users={managers}
            />
          </Provider>
          {simplifiedFormItem.parameters.product === "CYBER" && (
            <div>
              <div className="font-weight-bold">Devis CYBER</div>
              {dateDevisDemande}
              <LeadAttachedDocument
                lead={simplifiedFormItem}
                documentType="DEVIS_CYBER_PERCEVAL"
                canUpload={canIUploadQuotation}
              />
            </div>
          )}
          {simplifiedFormItem.parameters.product === "AC" && (
            <div>
              <div className="font-weight-bold">Devis AC</div>
              {dateDevisDemande}
              <LeadAttachedDocument
                lead={simplifiedFormItem}
                documentType="DEVIS_AC_PERCEVAL"
                canUpload={canIUploadQuotation}
              />
            </div>
          )}
        </div>
      </div>
      {bottomPanelContent && (
        <div className="row mx-0 bg-white border-left border-right border-bottom rounded-bottom">
          <div className="col-12 px-0">
            <ItemBottomPanel offsetBottom={0}>
              {(() => {
                switch (bottomPanelContent) {
                  case "TASKS":
                    return (
                      <Provider>
                        {/* <PanelSectionTitleAbs>Tâches</PanelSectionTitleAbs> */}
                        <TodoList
                          initTodos={simplifiedFormItem.tasks}
                          tasksByProduct={tasksByProduct}
                          onUpdateTodos={actions.onUpdateTasks}
                          onUpdateTodo={actions.onUpdateTask}
                          onAddTodo={actions.onAddTask}
                          onDeleteTodo={actions.onDeleteTask}
                          users={managers}
                        />
                      </Provider>
                    );
                  case "COMMENTS":
                    return (
                      <Provider>
                        {/* <PanelSectionTitleAbs>
                          Commentaires
                        </PanelSectionTitleAbs> */}
                        <CommentList
                          initComments={simplifiedFormItem.comments}
                          onUpdateComments={actions.onUpdateComments}
                          onUpdateComment={actions.onUpdateComment}
                          onAddComment={actions.onAddComment}
                          onDeleteComment={actions.onDeleteComment}
                          users={contacts}
                        />
                      </Provider>
                    );
                  case "INFOS":
                    return (
                      <SimplifiedFormWrapper
                        data={simplifiedFormItem}
                        itemKey={simplifiedFormItem.id}
                        myProducers={myProducers}
                        myRole={myRole}
                        onFormUpdate={actions.onFormUpdate}
                      />
                    );
                  default:
                    return null;
                }
              })()}
            </ItemBottomPanel>
          </div>
        </div>
      )}
    </StyledLeadItem>
  );
};

export default SimplifiedFormItem;
