import { Provider } from "jotai";
import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import tools from "../../../../../helpers/tools";
import DocumentList from "../../../../common/DocumentList/DocumentList";
import CommentList from "../../../../common/CommentList/CommentList";
import CommentListView from "../../../../common/CommentList/CommentListView/CommentListView";
import EmailLink from "../../../../common/CommentList/common/EmailLink/EmailLink";
import PhoneLink from "../../../../common/CommentList/common/PhoneLink/PhoneLink";
import CompanyInfo from "../../../../common/CompanyInfo/CompanyInfo";
import LabelValuePair from "../../../../common/LabelValuePair/LabelValuePair";
import LegalFormValue from "../../../../common/LegalFormValue/LegalFormValue";
import ProductInfos from "../../../../common/ProductInfos/ProductInfos";
import TodoList from "../../../../common/TodoList/TodoList";
import TodoListView from "../../../../common/TodoList/TodoListView/TodoListView";
import { LeadContext } from "../LeadContext";
import AssignTo from "./AssignTo/AssignTo";
import CreatedAt from "./CreatedAt/CreatedAt";
import LeadBottomPanel from "./LeadBottomPanel/LeadBottomPanel";
import LeadProduct from "./LeadProduct/LeadProduct";
import LeadStatus from "./LeadStatus/LeadStatus";
import useStatus from "./LeadStatus/useStatus";
import LinkedLeads from "./LinkedLeads/LinkedLeads";
import OpenDetailButton from "./OpenDetailButton/OpenDetailButton";
import useLeadItem from "./useLeadItem";
import {
  PanelSectionTitle,
  PanelSectionTitleAbs,
} from "../../../../common/layout/StyledComponents/StyledComponents";
import { Link } from "react-router-dom";
import { LeadPageContext } from "../../useLeads";
import { useLocation } from "react-router-dom";
import AttachedDocument from "./AttachedDocument/AttachedDocument";

const queryString = require("query-string");

const DocumentTypeByLeadType = {
  MRP: "DEVIS_MRP_LEAD_ENTREPRISE",
  AC: "DEVIS_AC_LEAD_ENTREPRISE",
  PJ: "DEVIS_PJ_LEAD_ENTREPRISE",
  CYBER: "DEVIS_CYBER_LEAD_ENTREPRISE",
  domi: "DEVIS_DOMI",
};

const LeadItem = ({ data }) => {
  const { productCategory } = useContext(LeadPageContext);
  const { lead, actions, bottomPanelContent, isSearchingFiles } = useLeadItem();
  const {
    tasksByProduct,
    managers,
    showOnlyLinkedLeads,
    fileTypes,
    contacts,
    myRole,
  } = useContext(LeadContext);
  const location = useLocation();
  const canIUploadQuotation = myRole && myRole != "PARTNER";

  useEffect(() => {
    const parsed = queryString.parse(location.search, {
      arrayFormat: "bracket",
    });
    let tm = null;
    if (data.id && parsed.leadId == data.id) {
      tm = setTimeout(() => {
        document
          .querySelector(`#leaditem${data.id}`)
          .scrollIntoView({ behavior: "smooth" });
      }, 2000);
    }
    return () => clearTimeout(tm);
  }, []);

  useEffect(() => {
    actions.initLead(data);
  }, [data]);

  const { statusRef } = useStatus(lead.status);

  const companyInfoByProductCategory = {
    PRO: {
      isHeadOffice: lead.establishment?.isHeadOffice,
      company: lead.client?.company,
      siret: lead.establishment?.siret,
      entityRef: "Lead",
      infos: [
        <OpenDetailButton
          style={{ fontSize: 13 }}
          onClick={actions.openInfos}
          title={<>Infos et documents</>}
        />,
        `${lead.establishment?.location?.postalCode} ${lead.establishment?.location?.city}`,
        `${lead.client?.ape} - ${lead.client?.companyActivity}`,
        tools.getFullName(lead.userCustomer),
        `Effectif : ${lead.establishment?.workforce}`,
        `Enregistrée le : ${tools.formatDate(
          lead.establishment?.registrationAt
        )}`,
        <PhoneLink phone={lead.userCustomer?.phone} />,
        <EmailLink email={lead.userCustomer?.email} />,
      ],
    },
    DOMI: {
      badgeText: "Particulier",
      showCompanyLink: false,
      company: `${lead.user?.firstname} ${lead.user?.lastname}`,
      entityRef: "Quote",
      infos: [
        `Tarif du devis : ${
          lead.prices?.length > 0 && lead.prices[lead.prices.length - 1].price
            ? `${lead.prices[lead.prices.length - 1].price + "€"}`
            : "N.D"
        }`,
        <PhoneLink phone={lead.user?.phone} />,
        <EmailLink email={lead.user?.email} />,
        <OpenDetailButton
          className="pt-3"
          onClick={actions.openInfos}
          title={<>Plus d'infos</>}
        />,
      ],
    },
  };

  const leadQuotationTypeId =
    fileTypes?.find((f) => f.code == DocumentTypeByLeadType[lead.productId])
      ?.id || null;

  const leadQuotationCode =
    fileTypes?.find((f) => f.code == DocumentTypeByLeadType[lead.productId])
      ?.code || null;

  return !lead.id ? null : (
    <StyledLeadItem
      className="shadow-sm rounded mb-3 position-relative"
      id={`leaditem${lead.id}`}
      style={{
        borderLeft: `3px solid var(--${statusRef?.color})`,
      }}
    >
      {!showOnlyLinkedLeads && <LinkedLeads />}

      <div
        className="row mx-0 bg-white pt-2 border-top border-right  "
        style={{
          // borderLeft: `5px solid var(--${statusRef?.color})`,
          minHeight: 236,
        }}
      >
        <div
          className="col-12 pb-2  d-flex align-items-start flex-wrap justify-content-between"
          style={{ gap: 5 }}
        >
          <AssignTo />
          <CreatedAt />
        </div>
        <div
          className="lead-status-column col-12 col-md-1 d-center flex-md-column justify-content-md-between  align-items-center flex-row justify-content-between py-2"
          style={{
            background: `var(--${statusRef?.color})`,
            color: "white",
            borderTopRightRadius: 5,
            // borderBottomRightRadius: 5,
            boxShadow: "inset -2px 0px 6px -4px #000000",
          }}
        >
          <LeadProduct />
          <LeadStatus />
        </div>
        <div className="col-12 col-md-3 pb-3">
          <CompanyInfo
            isOpened
            {...companyInfoByProductCategory[productCategory]}
          />
        </div>
        <div
          className="col-12 col-md-4 "
          style={
            {
              // borderLeft: "1px solid #f6f6f6",
              // borderRight: "1px solid #f6f6f6",
            }
          }
        >
          <Provider>
            <OpenDetailButton
              onClick={actions.openComments}
              title={
                <>
                  <StyledCountBadge disabled={lead.comments.length === 0}>
                    {lead.comments.length}
                  </StyledCountBadge>{" "}
                  Commentaires
                </>
              }
            />
            <CommentListView
              initComments={lead.comments}
              onUpdateComments={actions.onUpdateComments}
              onUpdateComment={actions.onUpdateComment}
              onAddComment={actions.onAddComment}
              users={contacts}
            />
          </Provider>
        </div>
        <div className="col-12 col-md-4 position-relative mt-3 mt-md-0">
          <Provider>
            <OpenDetailButton
              onClick={actions.openTasks}
              title={
                <>
                  <StyledCountBadge disabled={lead.tasks.length === 0}>
                    {lead.tasks.length}
                  </StyledCountBadge>{" "}
                  Tâches
                </>
              }
            />
            <TodoListView
              initTodos={lead.tasks}
              tasksByProduct={tasksByProduct}
              onUpdateTodos={actions.onUpdateTasks}
              onUpdateTodo={actions.onUpdateTask}
              users={managers}
            />
            <AttachedDocument
              lead={lead}
              documentType={leadQuotationTypeId}
              documentCode={leadQuotationCode}
              canUpload={canIUploadQuotation}
              entityRef={
                companyInfoByProductCategory[productCategory].entityRef
              }
            />
          </Provider>
        </div>
      </div>
      {bottomPanelContent && (
        <div className="row mx-0 border-left border-right border-bottom rounded-bottom">
          <div className="col-12 px-0">
            <LeadBottomPanel>
              {(() => {
                switch (bottomPanelContent) {
                  case "TASKS":
                    return (
                      <Provider>
                        {/* <PanelSectionTitleAbs>Tâches</PanelSectionTitleAbs> */}
                        <TodoList
                          initTodos={lead.tasks}
                          tasksByProduct={tasksByProduct}
                          onUpdateTodos={actions.onUpdateTasks}
                          onUpdateTodo={actions.onUpdateTask}
                          onAddTodo={actions.onAddTask}
                          onDeleteTodo={actions.onDeleteTask}
                          users={managers}
                        />
                      </Provider>
                    );
                  case "COMMENTS":
                    return (
                      <Provider>
                        {/* <PanelSectionTitleAbs>
                          Commentaires
                        </PanelSectionTitleAbs> */}
                        <CommentList
                          initComments={lead.comments}
                          onUpdateComments={actions.onUpdateComments}
                          onUpdateComment={actions.onUpdateComment}
                          onAddComment={actions.onAddComment}
                          onDeleteComment={actions.onDeleteComment}
                          users={contacts}
                        />
                      </Provider>
                    );

                  case "INFOS":
                    return (
                      <>
                        {lead.productId == "PJ" && (
                          <DocumentList
                            title="Documents PJ"
                            files={lead.files.filter(
                              (f) => f[0].entity.ref == "Quotation"
                            )}
                            isLoading={isSearchingFiles}
                            companyName={lead.client.company}
                            productName={lead.productId}
                          />
                        )}
                        {lead.productId == "MRP" && lead.isFinalized && (
                          <DocumentList
                            title="Documents MRP"
                            files={lead.files.filter(
                              (f) => f[0].entity.ref == "Quotation"
                            )}
                            isLoading={isSearchingFiles}
                            companyName={lead.client.company}
                            productName={lead.productId}
                          />
                        )}
                        {lead.productId == "CYBER" && lead.isFinalized && (
                          <DocumentList
                            title="Documents CYBER"
                            files={lead.files.filter(
                              (f) => f[0].entity.ref == "Quotation"
                            )}
                            isLoading={isSearchingFiles}
                            companyName={lead.client.company}
                            productName={lead.productId}
                          />
                        )}
                        {lead.productId == "domi" && (
                          <DocumentList
                            title="Documents DOMI"
                            files={lead.files.filter(
                              (f) => f[0].entity.ref == "Quote"
                            )}
                            isLoading={isSearchingFiles}
                            companyName={tools.getFullName(lead.user)}
                            productName={lead.productId}
                          />
                        )}
                        {lead.client && (
                          <DocumentList
                            title="Documents de la société"
                            files={lead.files.filter(
                              (f) => f[0].entity.ref == "Client"
                            )}
                            isLoading={isSearchingFiles}
                            companyName={lead.client.company}
                            productName={lead.productId}
                          />
                        )}
                        <Provider>
                          <ProductInfos
                            productId={lead.productId}
                            productData={lead.productData}
                          />
                          {lead.productId == "MRP" && (
                            <Link
                              target={"_blank"}
                              to={`/modify-answers/${lead.client.id}/MRP/${lead.establishment.id}/${lead.quotationId}`}
                              className="btn btn-link pt-3 pb-4 w-100 d-flex justify-content-center"
                              style={{ fontWeight: "400", fontSize: 16 }}
                            >
                              Modifier mes réponses
                            </Link>
                          )}
                        </Provider>

                        {lead.client && (
                          <>
                            <PanelSectionTitle>Société</PanelSectionTitle>
                            <div className="row">
                              <div className="col-12 col-md-6">
                                <CompanyInfo
                                  isHeadOffice
                                  company={lead.client.company}
                                  siret={lead.client.siret}
                                  infos={[
                                    tools.getFullName(lead.userCustomer),
                                    <PhoneLink
                                      phone={lead.userCustomer.phone}
                                    />,
                                    <EmailLink
                                      email={lead.userCustomer.email}
                                    />,
                                    <LabelValuePair
                                      label="Forme juridique"
                                      value={
                                        <LegalFormValue
                                          legalForm={lead.client.legalForm}
                                        />
                                      }
                                    />,
                                    <LabelValuePair
                                      label="Adresse"
                                      value={tools.getFullAddress(
                                        lead.client.location1
                                      )}
                                    />,
                                    <LabelValuePair
                                      label="CA"
                                      value={`${tools.truenumberWithSpaces(
                                        lead.client.turnover
                                      )} €`}
                                    />,
                                    <LabelValuePair
                                      label="Immatriculée le"
                                      value={tools.formatDate(
                                        lead.client.registrationAt
                                      )}
                                    />,
                                    <LabelValuePair
                                      label="Effectif"
                                      value={
                                        lead.client.workforce === 0 ||
                                        lead.client.workforce === null
                                          ? "0"
                                          : tools.truenumberWithSpaces(
                                              lead.client.workforce
                                            )
                                      }
                                    />,
                                    <LabelValuePair
                                      label="Nombre de contrats en cours chez Perceval"
                                      value={tools.truenumberWithSpaces(
                                        lead.nbPolicies
                                      )}
                                    />,

                                    ,
                                  ]}
                                />
                              </div>
                              <div className="col-12 col-md-6">
                                {!lead.establishment.isHeadOffice && (
                                  <CompanyInfo
                                    isHeadOffice={
                                      lead.establishment.isHeadOffice
                                    }
                                    company={lead.establishment.company}
                                    siret={lead.establishment.siret}
                                    infos={[
                                      <LabelValuePair
                                        label="Adresse"
                                        value={tools.getFullAddress(
                                          lead.establishment.location
                                        )}
                                      />,
                                      <LabelValuePair
                                        label="CA"
                                        value={`${tools.truenumberWithSpaces(
                                          lead.establishment.turnover
                                        )} €`}
                                      />,
                                      <LabelValuePair
                                        label="Immatriculée le"
                                        value={tools.formatDate(
                                          lead.establishment.registrationAt
                                        )}
                                      />,
                                      <LabelValuePair
                                        label="Effectif"
                                        value={
                                          lead.establishment.workforce === 0
                                            ? "0"
                                            : tools.truenumberWithSpaces(
                                                lead.establishment.workforce
                                              )
                                        }
                                      />,
                                      ,
                                    ]}
                                  />
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    );

                  default:
                    return null;
                }
              })()}
            </LeadBottomPanel>
          </div>
        </div>
      )}
    </StyledLeadItem>
  );
};

export const StyledLeadItem = styled.div`
  margin-top: 40px;
`;

export const StyledCountBadge = styled.span`
  background: ${({ disabled }) => (disabled ? `#cacaca` : `var(--primary)`)};
  color: white;
  padding: 0px 3px;
  font-weight: 600;
  font-style: normal;
  border-radius: 5px;
  min-width: 15px;
  display: inline-block;
  text-align: center;
  font-size: 11px;
`;

export default LeadItem;
