import { Provider } from "jotai";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import useCancelReasons from "../../../hooks/useCancelReasons/useCancelReasons";
import useManagers from "../../../hooks/useManagers/useManagers";
import Page from "../../common/layout/Page";
import Loader from "../../common/Loader";
import PjCount from "./PjCount/PjCount";
import PjItem from "./PjItem/PjItem";
import { PjItemContext } from "./pjItemContext";
import PjRenewListFilters from "./PjRenewListFilters/PjRenewListFilters";

import PjRenewListPagination from "./PjRenewListPagination";
import usePjRenewList from "./usePjRenewList";

const queryString = require("query-string");

const PjRenewList = () => {
  const location = useLocation();
  const { pjList, pjListFilters, actions, isLoadingPjList } = usePjRenewList();
  const { managers, contacts, getManagers, getContacts } = useManagers();
  const { cancelReasons, getCancelReasons } = useCancelReasons();

  useEffect(() => {
    getManagers();
    getContacts();
    getCancelReasons();
  }, []);

  useEffect(() => {
    if (managers.length) {
      const parsed = queryString.parse(location.search, {
        arrayFormat: "bracket",
      });
      if (Object.keys(parsed).length > 0) {
        const newFilters = {
          ...pjListFilters,
          filters: {
            ...pjListFilters.filters,
            renewableInNbDays: null,
            ...parsed,
          },
        };
        actions.getPjList(newFilters);
      } else {
        actions.getPjList();
      }
    }
  }, [managers, location.search]);

  return !pjList ? (
    <Loader />
  ) : (
    <Page title="Renouvellement des PJ">
      <PjRenewListFilters />
      {isLoadingPjList && <Loader />}
      <PjRenewListPagination pjCount={pjList.count} />
      <PjCount />
      {pjList.data.map((pjItem) => (
        <PjItemContext.Provider
          key={`pjitem${pjItem.id}`}
          value={{ pjItem, managers, contacts, cancelReasons }}
        >
          <Provider>
            <PjItem />
          </Provider>
        </PjItemContext.Provider>
      ))}
      <PjRenewListPagination pjCount={pjList.count} />
    </Page>
  );
};

export default PjRenewList;
