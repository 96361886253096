import { atom, useAtom } from "jotai";
import React, { useContext } from "react";
import dataService from "../../../helpers/dataService";
import tools from "../../../helpers/tools";
import useManagers from "../../../hooks/useManagers/useManagers";
import {
  getLeads,
  getLinkedLeads,
  searchLeads,
} from "../../../services/leadService";
import {
  isListInitAtom,
  isSearchingAtom,
  leadCountAtom,
  leadsAtom,
  leadsFiltersAtom,
  linkedLeadsAtom,
  showOnlyLinkedLeadsAtom,
} from "./atoms/leadListAtoms";

export const LeadPageContext = React.createContext();
export const LeadProvider = ({ value, children }) => {
  return (
    <LeadPageContext.Provider value={value}>
      {children}
    </LeadPageContext.Provider>
  );
};
export const filtersByProduct = {
  PRO: {
    page: 1,
    filters: {
      terms: "",
      products: [],
      assignedTo: [],
      statuses: [],
      beginAt: "",
      endAt: "",
      producer: "",
    },
  },
  DOMI: {
    page: 1,
    filters: {
      terms: "",
      products: [],
      assignedTo: [],
      statuses: [],
      beginAt: "",
      endAt: "",
    },
  },
};

const useLeads = () => {
  const { productCategory } = useContext(LeadPageContext);
  const [leads, setLeads] = useAtom(leadsAtom);
  const [linkedLeads, setLinkedLeads] = useAtom(linkedLeadsAtom);
  const [leadsFilters, setLeadsFilters] = useAtom(leadsFiltersAtom);
  const [isSearching, setIsSearching] = useAtom(isSearchingAtom);
  const [leadCount, setLeadCount] = useAtom(leadCountAtom);
  const [isListInit, setIsListInit] = useAtom(isListInitAtom);
  const [showOnlyLinkedLeads, setShowOnlyLinkedLeads] = useAtom(
    showOnlyLinkedLeadsAtom
  );
  const { managers, contacts } = useManagers();

  const resetAtoms = ({ newLeadFilters }) => {
    let freshFilters = filtersByProduct[productCategory];
    if (newLeadFilters) {
      freshFilters = {
        ...freshFilters,
        filters: { ...freshFilters.filters, ...newLeadFilters },
      };
    }
    setLeads([]);
    setLinkedLeads([]);
    setIsListInit(false);
    setIsSearching(false);
    setLeadCount(0);
    setShowOnlyLinkedLeads(false);
    setLeadsFilters(freshFilters);
    onGetLeads(freshFilters);
  };

  const getFormattedLeads = (apiLeads) => {
    const newLeads = apiLeads.map((d) => {
      return {
        ...d,
        tasks: tools.populateNamesFromId(managers, d.tasks),
        comments: tools.populateNamesFromId(contacts, d.comments),
      };
    });
    return newLeads;
  };

  const onGetLinkedLeads = (quotationId) => {
    setIsSearching(true);
    setShowOnlyLinkedLeads(true);
    setLinkedLeads([]);
    getLinkedLeads(quotationId)
      .then((data) => setLinkedLeads(getFormattedLeads(data)))
      .finally(() => setIsSearching(false));
  };

  const onGetLeads = (overrideFilters = null) => {
    let formattedFilters = overrideFilters || {
      ...leadsFilters,
      filters: { ...leadsFilters.filters, leadId: undefined },
    };
    formattedFilters = {
      ...formattedFilters,
      filters: {
        ...formattedFilters.filters,
        beginAt: formattedFilters.filters.beginAt || "",
        endAt: formattedFilters.filters.endAt || "",
      },
    };
    setIsSearching(true);
    searchLeads(formattedFilters, productCategory)
      .then((data) => {
        setLeadCount(data.count);
        setLeads(getFormattedLeads(data.data));
        setIsListInit(true);
      })
      .finally(() => setIsSearching(false));
  };

  return {
    leads,
    leadCount,
    isListInit,
    isSearching,
    linkedLeads,
    setLinkedLeads,
    showOnlyLinkedLeads,
    setShowOnlyLinkedLeads,
    productCategory,
    actions: {
      getLeads: onGetLeads,
      getLinkedLeads: onGetLinkedLeads,
      resetAtoms,
    },
  };
};

export default useLeads;
