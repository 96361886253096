import { useState, useEffect, useRef } from "react";
import useStore from "../../context/useStore";
import useRole from "../useRole/useRole";

export const useChat = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [shouldAutoScroll, setShouldAutoScroll] = useState(true);
  const [isStreaming, setIsStreaming] = useState(false);
  const [width, setWidth] = useState(350);
  const [height, setHeight] = useState(500);
  const [isResizing, setIsResizing] = useState(false);
  const [initialPos, setInitialPos] = useState({ x: 0, y: 0 });
  const [initialSize, setInitialSize] = useState({ width: 350, height: 500 });
  const [hasShownIntro, setHasShownIntro] = useState(false);
  const messagesEndRef = useRef(null);
  const chatContainerRef = useRef(null);
  const abortControllerRef = useRef(null);
  const [state] = useStore();
  const { myRole } = useRole();

  useEffect(() => {
    if (shouldAutoScroll && messages[messages.length - 1]?.isUser) {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages, shouldAutoScroll]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setWidth(entry.contentRect.width);
        setHeight(entry.contentRect.height);
      }
    });

    if (chatContainerRef.current) {
      resizeObserver.observe(chatContainerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    if (isOpen && !hasShownIntro) {
      setMessages((prevMessages) => [
        {
          content:
            "Je suis votre allié pour utiliser le Backoffice Perceval. Comment puis-je vous aider ?",
          isUser: false,
        },
        ...prevMessages,
      ]);
      setHasShownIntro(true);
    }
  }, [isOpen, hasShownIntro]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const toggleChat = () => {
    setIsOpen(!isOpen);
  };

  const stopStream = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      abortControllerRef.current = null;
      setIsStreaming(false);
      setIsLoading(false);

      // Update the last message to indicate the stream was stopped
      setMessages((prev) => {
        const newMessages = [...prev];
        const lastMessage = newMessages[newMessages.length - 1];
        if (!lastMessage.isUser) {
          lastMessage.isStreaming = false;
          lastMessage.content += "\n\n_Message interrompu par l'utilisateur_";
        }
        return newMessages;
      });
    }
  };

  const sendMessage = async () => {
    if (!input.trim() || isLoading) return;

    setShouldAutoScroll(true);
    setIsStreaming(false);
    const userMessage = input.trim();
    setInput("");
    setMessages((prev) => {
      const newMessages = [...prev, { content: userMessage, isUser: true }];
      setTimeout(() => scrollToBottom(), 0);
      return newMessages;
    });
    setIsLoading(true);

    try {
      setIsStreaming(true);
      setMessages((prev) => [
        ...prev,
        { content: "", isUser: false, isStreaming: true },
      ]);

      // Create new AbortController for this request
      abortControllerRef.current = new AbortController();

      const response = await fetch(`/api/chat`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          message: userMessage,
          userId: state.auth.user.id,
        }),
        signal: abortControllerRef.current.signal,
      });

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;

        const events = decoder.decode(value).split("\n\n");
        for (const event of events) {
          if (!event.trim()) continue;

          const lines = event.split("\n");
          const eventType = lines[0].replace("event: ", "");
          const data = JSON.parse(lines[1].replace("data: ", ""));

          switch (eventType) {
            case "delta":
              setMessages((prev) => {
                const newMessages = [...prev];
                const lastMessage = newMessages[newMessages.length - 1];
                if (!lastMessage.isUser) {
                  lastMessage.content += data.delta;
                }
                return newMessages;
              });
              break;
            case "error":
              console.error("Streaming error:", data.error);
              setMessages((prev) => {
                const newMessages = [...prev];
                const lastMessage = newMessages[newMessages.length - 1];
                if (!lastMessage.isUser) {
                  lastMessage.content =
                    "Une erreur est survenue lors du traitement de votre message.";
                  lastMessage.isStreaming = false;
                }
                return newMessages;
              });
              setIsStreaming(false);
              break;
            case "end":
              setMessages((prev) => {
                const newMessages = [...prev];
                const lastMessage = newMessages[newMessages.length - 1];
                if (!lastMessage.isUser) {
                  lastMessage.isStreaming = false;
                }
                return newMessages;
              });
              setIsStreaming(false);
              break;
            default:
              break;
          }
        }
      }
    } catch (error) {
      console.error("Error sending message:", error);
      setMessages((prev) => [
        ...prev,
        {
          content:
            "Désolé, une erreur est survenue lors du traitement de votre message.",
          isUser: false,
        },
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      sendMessage();
    }
  };

  const handleMouseDown = (e) => {
    setIsResizing(true);
    setInitialPos({ x: e.clientX, y: e.clientY });
    setInitialSize({ width, height });
  };

  const handleMouseMove = (e) => {
    if (!isResizing) return;

    const deltaX = initialPos.x - e.clientX;
    const deltaY = initialPos.y - e.clientY;

    const newWidth = Math.min(Math.max(initialSize.width + deltaX, 300), 800);
    const newHeight = Math.min(Math.max(initialSize.height + deltaY, 400), 800);

    setWidth(newWidth);
    setHeight(newHeight);
  };

  const handleMouseUp = () => {
    setIsResizing(false);
  };

  useEffect(() => {
    if (isResizing) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isResizing, initialPos, initialSize]);

  return {
    messages,
    input,
    isOpen,
    shouldAutoScroll,
    isStreaming,
    width,
    height,
    messagesEndRef,
    chatContainerRef,
    setInput,
    toggleChat,
    sendMessage,
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
    handleKeyPress,
    stopStream,
    isLoading,
    setShouldAutoScroll,
  };
};

export default useChat;
