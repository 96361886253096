import React from "react";
import tools from "../../../../../helpers/tools";

const PhoneLink = ({ phone }) => {
  if (!phone)
    return (
      <span>
        <i className="fa fa-phone mr-2" />
        inconnu
      </span>
    );
  return (
    <a href={`tel:${phone}`}>
      <i className="fa fa-phone mr-2" />
      {tools.formatPhone(phone)}
    </a>
  );
};

export default PhoneLink;
