import { atom, useAtom } from "jotai";
import { toast } from "react-toastify";

import React from "react";
import {
  onDeleteLead,
  onSearchLeads,
} from "../../../services/graal-api/simplifiedFormService";
import tools from "../../../helpers/tools";
import useManagers from "../../../hooks/useManagers/useManagers";
import useGlobalError from "../../../hooks/useGlobalError/useGlobalError";
import useGraalProducers from "../../../hooks/useProducers/useGraalProducers";

export const simplifiedFormListAtom = atom(null);
export const simplifiedFormFiltersAtom = atom({
  partenaire_lie: "",
  client_lie: "",
  nom_societe_liee: "",
  siren_siret_societe_liee: "",
  produits: [],
  page: 1,
});
export const isCreateModeAtom = atom(false);
export const isLoadingAtom = atom(false);
export const nbLeadAtom = atom(0);

export const SimplifiedFormListContext = React.createContext();
export const SimplifiedFormListProvider = ({ value, children }) => {
  return (
    <SimplifiedFormListContext.Provider value={value}>
      {children}
    </SimplifiedFormListContext.Provider>
  );
};

const useSimplifiedFormList = () => {
  const { myProducers, iAmPartner } = useGraalProducers();
  const [simplifiedFormList, setSimplifiedFormList] = useAtom(
    simplifiedFormListAtom
  );
  const [isCreateMode, setIsCreateMode] = useAtom(isCreateModeAtom);
  const [simplifiedFormFilters, setSimplifiedFormFilters] = useAtom(
    simplifiedFormFiltersAtom
  );
  const [nbLead, setNbLead] = useAtom(nbLeadAtom);
  const { managers, contacts } = useManagers();
  const [isLoading, setIsLoading] = useAtom(isLoadingAtom);
  const { globalErrorActions } = useGlobalError();

  const actions = {
    onFetchSimplifiedFormList: async (filters = {}) => {
      setIsLoading(true);
      onSearchLeads({
        ...filters,
        partenaire_lie:
          myProducers.length == 1
            ? myProducers[0].uuid
            : filters?.partenaire_lie,
      })
        .then((results) => {
          setSimplifiedFormList(actions.getFormattedLeads(results.data.data));
          setNbLead(results.data.meta.total);
          setIsLoading(false);
        })
        .catch((err) => {
          globalErrorActions.handleGlobalError(err.response.status, {
            callback: () => {
              actions.onFetchSimplifiedFormList(filters);
            },
          });
          setIsLoading(false);
        });
    },
    onClickCreateNewLead: () => {
      setIsCreateMode(true);
    },
    onClickReturnToList: () => {
      setIsCreateMode(false);
    },
    onNewLeadCreated: (newLead) => {
      toast.success("Nouveau lead interne créé");
      actions.onClickReturnToList();
      actions.onFetchSimplifiedFormList(simplifiedFormFilters);
      // modal_actions.updateSidenav({
      //   isOpen: false,
      //   content: null,
      // });
    },
    onFiltersChange: (e) => {
      const { name, value } = e.target;
      setSimplifiedFormFilters((old) => ({
        ...old,
        [name]: value,
      }));
    },
    updatePage: (page) => {
      const newFilters = { ...simplifiedFormFilters, page };
      setSimplifiedFormFilters(newFilters);
      actions.onFetchSimplifiedFormList(newFilters);
    },
    getFormattedLeads: (apiLeads) => {
      const newLeads = apiLeads.map((d) => {
        return {
          ...d,
          tasks: tools.populateNamesFromId(managers, d.tasks),
          comments: tools.populateNamesFromId(contacts, d.comments),
        };
      });
      return newLeads;
    },
    onDeleteLead: (leadId) => {
      const oldList = [...simplifiedFormList];
      setSimplifiedFormList((old) => old.filter((l) => l.id != leadId));

      onDeleteLead(leadId)
        .then(() => toast.success("Ce lead a bien été supprimé"))
        .catch((err) => {
          toast.error(
            `Erreur lors de la suppression du lead ${err.response?.data?.message}`
          );

          globalErrorActions.handleGlobalError(err.response.status, {
            callback: () => actions.onDeleteLead(leadId),
          });
          setSimplifiedFormList(oldList);
        });
    },
  };
  return {
    simplifiedFormList,
    isCreateMode,
    simplifiedFormFilters,
    actions,
    isLoading,
    nbLead,
  };
};

export default useSimplifiedFormList;
