import React from "react";

const EmailLink = ({ email }) => {
  if (!email)
    return (
      <span>
        <i className="fa fa-envelope mr-2" />
        inconnu
      </span>
    );

  return (
    <a
      href={`mailto:${email}`}
      style={{
        overflowWrap: "anywhere",
      }}
    >
      <i className="fa fa-envelope mr-2" />
      {!email ? "inconnu" : email}
    </a>
  );
};

export default EmailLink;
