import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";
import dataService from "../../../../../../helpers/dataService";
import { API_URL } from "../../../../../../config";
import DropZone from "../../../../ClientListPage/ClientList/ClientItem/CompanyList/CompanyItem/BottomPanel/BPDocuments/BPDocumentItem/DropZone";
import tools from "../../../../../../helpers/tools";
import Loader from "../../../../../common/Loader";
import { DateTime } from "luxon";

/*
Payload pour uploader un fichier : 
Route POST /files

{
    entityId: 6757838b7d9524001dcee486, // id du lead
    entityRef: 'Lead',
    fileType: '675828c24c7cef327ed2ef77', // id du type de fichier selon le devis qu'on souhaite envoyer
    file: fichier.pdf
}

*/

const AttachedDocument = ({
  lead,
  canUpload,
  documentType,
  documentCode,
  entityRef,
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [progress, setProgress] = useState(0);
  const [uploadedDocument, setUploadedDocument] = useState(null);

  const onDropFiles = (files) => {
    updateFile(files);
  };

  function updateFile(files) {
    const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];

    if (files.length > 0 && allowedTypes.indexOf(files[0].type) != -1) {
      let data = new FormData();
      data.append("file", files[0]);
      data.append("fileType", documentType);
      data.append("entityRef", entityRef);
      data.append("entityId", lead.id);

      uploadFile(data);
    } else {
      toast.error(
        "Le fichier choisi est incorrect. Les formats acceptés sont jpeg, png et pdf."
      );
    }
  }
  function uploadFile(data) {
    setIsUploading(true);
    dataService.postFile(
      "files",
      data,
      (data) => {
        onDocumentUploaded(data);
      },
      (err) => {
        toast.error("L'envoi du document a échoué. Veuillez réessayer.");
      },
      () => {
        setIsUploading(false);
      }
    );
  }

  const onDocumentUploaded = (data) => {
    setIsUploaded(true);
    setUploadedDocument(data);
  };

  return (
    <div>
      {isUploaded && (
        <div className="text-info">
          <i className="fa fa-check mr-2" />
          Document envoyé avec succès
        </div>
      )}
      <ExistingDocument
        lead={lead}
        documentCode={documentCode}
        uploadedDocument={uploadedDocument}
      />
      {canUpload && (
        <DropZone onDropFiles={onDropFiles} isUploading={isUploading} />
      )}
    </div>
  );
};

export default AttachedDocument;

const ExistingDocument = ({ lead, documentCode, uploadedDocument }) => {
  const [isDownloading, setIsDownloading] = useState(false);

  // On cherche d'abord dans uploadedDocument, puis dans lead.medias
  const docRef =
    uploadedDocument?.fileType?.code === documentCode
      ? {
          ...uploadedDocument,
          fileType: {
            ...uploadedDocument.fileType,
            name: uploadedDocument.fileType.name || documentCode,
          },
        }
      : lead.medias && Array.isArray(lead.medias)
      ? lead.medias.find((d) => d.fileType?.code === documentCode)
      : null;

  if (!docRef) {
    return <i className="text-danger">aucun document</i>;
  }

  const onDownload = (preview = false) => {
    if (isDownloading) return false;
    setIsDownloading(docRef.id);
    let docExt = docRef.fileName.split(".");
    docExt = docExt[docExt.length - 1];
    dataService.downloadFile(
      `files/${docRef.id}`,
      {
        preview,
        filename: tools.getDocumentName({
          fileExt: docExt,
          documentName: docRef?.fileType?.name || "",
          productName: lead.productId || "",
          companyName:
            lead.client?.company || tools.getFullName(lead.user) || "",
          uploadedAt: DateTime.fromISO(docRef.uploadedAt).toFormat("yyyy"),
        }),
      },
      (data) => {},
      () => {
        toast.error(
          "Le téléchargement du document a échoué. Veuillez réessayer."
        );
      },
      () => setIsDownloading(false)
    );
  };

  return (
    <div className="mb-2">
      <div
        className="d-flex align-items-center"
        style={{ fontSize: 12, gap: 5 }}
      >
        <div className="badge badge-success rounded-circle d-center">
          <i className="fa fa-check text-white" />
        </div>
        envoyé le {tools.formatDate(docRef.uploadedAt, "dd/MM/yyyy HH:mm")}
      </div>
      <button
        className="btn btn-link border-0 px-0 text-left font-weight-normal py-2 d-center"
        style={{ fontSize: 12 }}
        onClick={() => onDownload()}
      >
        {isDownloading ? (
          <Loader size={15} className="mr-2" />
        ) : (
          <i className="fa fa-download mr-2"></i>
        )}
        {docRef.fileType?.name}
      </button>
    </div>
  );
};
