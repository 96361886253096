import { Provider } from "jotai";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import useManagers from "../../../../hooks/useManagers/useManagers";
import useTasks from "../../../../hooks/useTasks/useTasks";
import { StyledLeadList } from "../../LeadListPage/LeadList/LeadList";
import { ItemContext } from "../SimplifiedFormItem/ItemContext";
import SimplifiedFormItem from "../SimplifiedFormItem/SimplifiedFormItem";
import useSimplifiedFormList from "../useSimplifiedFormList";
const queryString = require("query-string");

const SimplifiedFormList = ({ isLoading }) => {
  const { simplifiedFormList, actions } = useSimplifiedFormList();
  const { tasksByProduct } = useTasks();
  const { managers, contacts } = useManagers();
  const location = useLocation();

  useEffect(() => {
    // if (simplifiedFormList && simplifiedFormList.length > 0) {
    //   return;
    // }
    const parsed = queryString.parse(location.search, {
      arrayFormat: "bracket",
    });
    let newLeadFilters = {};
    if (Object.values(parsed).length > 0) {
      newLeadFilters = parsed;
    }
    actions.onFetchSimplifiedFormList(newLeadFilters);
  }, [location.search]);

  if (!simplifiedFormList || isLoading) return null;

  return (
    <StyledLeadList>
      {simplifiedFormList.map((simplifiedFormItem) => {
        return (
          <div
            className="leadListCard"
            key={`simplifiedFormItem${simplifiedFormItem.id}`}
          >
            <ItemContext.Provider
              value={{
                tasksByProduct:
                  tasksByProduct[simplifiedFormItem.parameters?.product],
                managers,
                contacts,
                onDeleteLead: actions.onDeleteLead,
              }}
            >
              <Provider>
                <SimplifiedFormItem data={simplifiedFormItem} />
              </Provider>
            </ItemContext.Provider>
          </div>
        );
      })}
    </StyledLeadList>
  );
};

export default SimplifiedFormList;
