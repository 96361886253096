import { atom, useAtom } from "jotai";
import { DateTime } from "luxon";
import { useState } from "react";
import { toast } from "react-toastify";
import useStore from "../../../context/useStore";
import dataService from "../../../helpers/dataService";
import useProducts from "../../../hooks/useProducts/useProducts";
import useProductTypes from "../../../hooks/useProductTypes/useProductTypes";
import {
  getFileFilters,
  getFileTypes,
  searchFiles,
} from "../../../services/fileService";

const searchAtom = atom({
  file: {
    entityModel: "",
    fileTypes: [],
    filters: [],
    isExist: "-",
    uploadedAt: "",
    activeUsersOnly: false,
    products: [],
    productInclusionType: "", //all, include, exclude
  },
  page: 1,
});

const searchTextAtom = atom("Rechercher");
const isLoadingAtom = atom(false);
const customFiltersAtom = atom([]);
const displayInlineAtom = atom(true);
const documentsAtom = atom([]);
const errorsAtom = atom(false);
const nbPagesAtom = atom(1);
const nbResultsAtom = atom(0);
const fileTypesAtom = atom([]);

const resetPageAtom = atom(false);

const useDocumentList = () => {
  const [state, dispatch] = useStore();
  const [search, setSearch] = useAtom(searchAtom);
  const [isLoading, setIsLoading] = useAtom(isLoadingAtom);
  const [searchText, setSearchText] = useAtom(searchTextAtom);
  const [customFilters, setCustomFilters] = useAtom(customFiltersAtom);
  const [displayInline, setDisplayInline] = useAtom(displayInlineAtom);
  const [documents, setDocuments] = useAtom(documentsAtom);
  const [errors, setError] = useAtom(errorsAtom);
  const [nbResults, setNbResults] = useAtom(nbResultsAtom);
  const [fileTypes, setFileTypes] = useAtom(fileTypesAtom);
  const fileEntitiesData = state.constants.items
    ? state.constants.items.FILE_ENTITIES_MODEL
    : false;
  const [isInit, setIsInit] = useState(false);
  const { products, onGetProducts } = useProducts();
  const { onGetProductTypes } = useProductTypes();

  const getDatas = () => {
    getFileFilters().then(setCustomFilters);
    getFileTypes().then(setFileTypes);
    onGetProducts();
    onGetProductTypes();
  };

  const initFileTypes = () => {
    getFileTypes().then(setFileTypes);
  };

  const searchDocuments = ({ overrideSearch = false } = {}) => {
    var filters = overrideSearch || search;
    var formatedSearch = {
      ...filters,
      file: {
        ...filters.file,
        isExist: filters.file.isExist == "-" ? undefined : filters.file.isExist,
      },
    };

    setError(false);
    setIsLoading(true);
    searchFiles(formatedSearch.file)
      .then((data) => {
        setDocuments(data);
        setNbResults(data.length || 0);
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err);
        setDocuments([]);
      })
      .finally(() => setIsLoading(false));
  };

  const updateDocumentSearch = (e) => {
    console.log(e);
    var { name, value, type, checked } = e.target;
    var realValue = value;
    if (type == "checkbox") {
      realValue = checked ? true : false;
    }

    var su = { ...search };
    su.file[name] = realValue;
    su.file.ATTESTATION_EXPIRED = false;
    if (name == "entityModel") {
      su.file.fileTypes = [];
      su.file.products = [];
      su.file.productInclusionType = "";
    }
    if (name == "isExist") {
      su.file.uploadedAt = "";
    }
    setSearch(su);
  };

  const documentUploaded = (k, ck, doc, entk) => {
    console.log("UPLOADED", k, ck, doc, entk);
    var dcs = JSON.parse(JSON.stringify(documents));

    dcs[entk].files[k][0] = JSON.parse(JSON.stringify(doc));
    toast.success("Document envoyé avec succès");
    setDocuments(dcs);
  };

  const documentRemoved = (k, ck, oldDoc, entk) => {
    var dcs = JSON.parse(JSON.stringify(documents));

    dcs[entk].files[k] = dcs[k].filter((dc, dck) => {
      return dck != ck;
    });

    if (dcs[entk].files[k].length == 0) {
      dcs[entk].files[k].push({
        fileType: oldDoc.fileType,
        entity: oldDoc.entity,
      });
    }
    toast.success("Document supprimé avec succès");
    setDocuments(dcs);
  };

  const uploadNewFile = (k, ck, entk) => {
    var dcs = JSON.parse(JSON.stringify(documents));

    dcs[entk].files[k] = [
      {
        fileType: dcs[entk].files[k][ck].fileType,
        entity: dcs[entk].files[k][ck].entity,
        isNewVersion: true,
      },
    ].concat(dcs[entk].files[k]);

    setDocuments(dcs);
  };

  const updateSearchWithQueryParams = (params) => {
    if (params?.isExist == "true") {
      params.isExist = true;
    }
    if (params?.isExist == "false") {
      params.isExist = false;
    }
    if (params?.ATTESTATION_EXPIRED == "true") {
      params.ATTESTATION_EXPIRED = true;
    }
    if (params?.fileTypes) {
      params.fileTypes = params.fileTypes.map(
        (f) => fileTypes.find((ft) => ft.code == f).id
      );
    }
    if (params?.activeUsersOnly == "true") {
      params.activeUsersOnly = true;
    }
    if (params?.activeUsersOnly == "false") {
      params.activeUsersOnly = false;
    }

    const newSearch = {
      ...search,
      file: {
        ...search.file,
        activeUsersOnly: false,
        products: [],
        productInclusionType: "",
        filters: [],
        ...params,
      },
    };
    searchDocuments({ overrideSearch: newSearch });
    setSearch(newSearch);
  };

  const searchAmendmentsWithMissingCP = () => {
    const fileTypeId = fileTypes.find((f) => f.code == "CP").id;
    const newSearch = {
      file: {
        entityModel: "Amendment",
        fileTypes: [fileTypeId],
        filters: [],
        isExist: false,
        uploadedAt: "",
        products: [],
      },
      page: 1,
    };
    searchDocuments({ overrideSearch: newSearch });
    setSearch(newSearch);
  };
  const searchAmendmentsWithMissingAttestation = () => {
    const fileTypeId = fileTypes.find((f) => f.code == "ATT_ASSU_CONT").id;
    const newSearch = {
      file: {
        entityModel: "Amendment",
        fileTypes: [fileTypeId],
        filters: [],
        isExist: false,
        uploadedAt: "",
        products: [],
      },
      page: 1,
    };
    searchDocuments({ overrideSearch: newSearch });
    setSearch(newSearch);
  };
  const searchAmendmentsWithOutdatedAttestation = () => {
    const fileTypeId = fileTypes.find((f) => f.code == "ATT_ASSU_CONT").id;

    const newSearch = {
      file: {
        entityModel: "Amendment",
        fileTypes: [fileTypeId],
        filters: [],
        isExist: true,
        ATTESTATION_EXPIRED: true,
        products: [],
      },
      page: 1,
    };
    searchDocuments({ overrideSearch: newSearch });
    setSearch(newSearch);
  };
  const searchUsersWithMissingID = () => {
    const fileTypeId = fileTypes.find((f) => f.code == "ID").id;
    const newSearch = {
      file: {
        entityModel: "Client",
        fileTypes: [fileTypeId],
        filters: [],
        isExist: false,
        uploadedAt: "",
        products: [],
      },
      page: 1,
    };
    searchDocuments({ overrideSearch: newSearch });
    setSearch(newSearch);
  };
  const searchUsersWithMissingKbis = () => {
    const fileTypeId = fileTypes.find((f) => f.code == "KBIS").id;
    const newSearch = {
      file: {
        entityModel: "Client",
        fileTypes: [fileTypeId],
        filters: [],
        isExist: false,
        uploadedAt: "",
        products: [],
      },
    };
    searchDocuments({ overrideSearch: newSearch });
    setSearch(newSearch);
  };
  const searchUsersWithOutdatedKbis = () => {
    const fileTypeId = fileTypes.find((f) => f.code == "KBIS").id;

    const newSearch = {
      file: {
        entityModel: "Client",
        fileTypes: [fileTypeId],
        filters: [],
        isExist: true,
        uploadedAt: DateTime.local().minus({ years: 1 }).toISO(),
        products: [],
      },
    };
    searchDocuments({ overrideSearch: newSearch });
    setSearch(newSearch);
  };

  return {
    documents,
    isLoading,
    search,
    searchText,
    setSearchText,
    isInit,
    setIsInit,
    nbResults,
    customFilters,
    fileTypes,
    displayInline,
    setDisplayInline,
    errors,
    fileEntitiesData,
    getDatas,
    searchDocuments,
    updateDocumentSearch,
    documentUploaded,
    documentRemoved,
    uploadNewFile,
    updateSearchWithQueryParams,
    initFileTypes,
    fastSearches: {
      searchAmendmentsWithMissingCP,
      searchAmendmentsWithMissingAttestation,
      searchAmendmentsWithOutdatedAttestation,
      searchUsersWithMissingID,
      searchUsersWithMissingKbis,
      searchUsersWithOutdatedKbis,
    },
  };
};

export default useDocumentList;
