import { useAtom } from "jotai";
import { useContext } from "react";
import { toast } from "react-toastify";
import dataService from "../../../../../helpers/dataService";
import tools from "../../../../../helpers/tools";
import {
  deleteComment,
  patchComment,
  postComment,
} from "../../../../../services/commentService";
import { getCompanyFiles } from "../../../../../services/fileService";
import {
  deleteTask,
  patchTask,
  patchTasksOrder,
  postTask,
} from "../../../../../services/taskService";
import { LeadPageContext } from "../../useLeads";
import { LeadContext } from "../LeadContext";
import {
  bottomPanelContentAtom,
  isFilesLoadedAtom,
  isLeadLoadingAtom,
  isSearchingFilesAtom,
  leadAtom,
} from "./atoms/leadAtoms";

const entityRefByCategory = {
  PRO: "Lead",
  DOMI: "Quote",
};

const useLeadItem = () => {
  const { productCategory } = useContext(LeadPageContext);
  const [lead, setLead] = useAtom(leadAtom);
  const [isLeadLoading, setIsLeadLoading] = useAtom(isLeadLoadingAtom);
  const { managers, contacts } = useContext(LeadContext);
  const [bottomPanelContent, setBottomPanelContent] = useAtom(
    bottomPanelContentAtom
  );
  const [isSearchingFiles, setIsSearchingFiles] = useAtom(isSearchingFilesAtom);
  const [isFilesLoaded, setIsFilesLoaded] = useAtom(isFilesLoadedAtom);

  const initLead = (data) => {
    setLead({ ...data, files: [] });
  };

  const getFormattedLeadTasks = (rawLead) => {
    const newLead = {
      ...rawLead,
      tasks: tools.populateNamesFromId(managers, rawLead.tasks),
    };
    return newLead;
  };
  const getFormattedLeadComments = (rawLead) => {
    const newLead = {
      ...rawLead,
      comments: tools.populateNamesFromId(contacts, rawLead.comments),
    };
    return newLead;
  };

  const openTasks = () => {
    const isAlreadyOpen = bottomPanelContent == "TASKS";
    setBottomPanelContent(isAlreadyOpen ? null : "TASKS");
    scrollLeadToTop();
  };
  const openComments = () => {
    const isAlreadyOpen = bottomPanelContent == "COMMENTS";
    setBottomPanelContent(isAlreadyOpen ? null : "COMMENTS");
    scrollLeadToTop();
  };
  const openInfos = async () => {
    const isAlreadyOpen = bottomPanelContent == "INFOS";
    setBottomPanelContent(isAlreadyOpen ? null : "INFOS");
    scrollLeadToTop();

    if (isFilesLoaded) return;
    setIsSearchingFiles(true);
    let companyFiles = [];
    if (lead.client) {
      companyFiles = await getCompanyFiles(lead.client.id);
    }
    let otherFiles = [];
    if (lead.productId == "MRP" && lead.isFinalized) {
      let MRPFiles = await getLeadMRPFiles();
      otherFiles = MRPFiles.map((f) => [f]);
    }
    if (lead.productId == "PJ") {
      let PJFiles = await getLeadPJFiles();
      otherFiles = PJFiles.map((f) => [f]);
    }
    if (lead.productId == "domi") {
      otherFiles = await getLeadDOMIFiles();
    }
    const newLead = {
      ...lead,
      files: [...lead.files, ...companyFiles, ...otherFiles],
    };
    setIsFilesLoaded(true);
    setIsSearchingFiles(false);
    setLead(newLead);
  };

  const closeBottomPanel = () => {
    setBottomPanelContent(false);
  };

  const onUpdateTasks = (tasks) => {
    console.log("UPDATE TASKS");
    const oldLead = { ...lead };
    setLead({ ...lead, tasks });
    setIsLeadLoading(true);
    patchTasksOrder(lead.tasksId, { tasks })
      .then((data) => {
        toast.success("Tâches réordonnées");
        setLead(getFormattedLeadTasks({ ...lead, tasks: data.tasks }));
      })
      .catch((err) => setLead(oldLead))
      .finally(() => setIsLeadLoading(false));
  };
  const onUpdateTask = (task) => {
    console.log("UPDATE TASK", task);

    // PATCH /tasks/:id
    // body {
    //   "taskId": VOIR CONSTANTS LEAD_TASKS,
    //   "text": "blabla" || ""
    // }
    const oldLead = { ...lead };
    setLead({
      ...lead,
      tasks: [...lead.tasks.map((t) => (t.id == task.id ? task : t))],
    });
    setIsLeadLoading(true);
    patchTask(task.id, task)
      .then((data) => {
        console.log("DATA", data);
        toast.success("Tâche mise à jour");
        setLead(getFormattedLeadTasks({ ...lead, tasks: data.tasks }));
      })
      .catch((err) => setLead(oldLead))
      .finally(() => setIsLeadLoading(false));
  };
  const onAddTask = (task) => {
    console.log("ADD TASK", task);

    // POST /tasks
    // body {
    //     "entity": "62274fa25202ee0198b206fc",
    //     "entityRef": "Lead",
    //     "taskId": VOIR CONSTANTS LEAD_TASKS,
    //     "text": "blabla" || ""
    // }
    const oldLead = { ...lead };

    setIsLeadLoading(true);
    postTask({
      entity: lead.id,
      entityRef: entityRefByCategory[productCategory],
      ...task,
    })
      .then((data) => {
        toast.success("Tâche ajoutée");
        //const addedTask = data.tasks[data.tasks.length - 1];
        setLead(getFormattedLeadTasks({ ...lead, tasks: data.tasks }));
      })
      .catch((err) => setLead(oldLead))
      .finally(() => setIsLeadLoading(false));
  };
  const onDeleteTask = (taskId) => {
    console.log("DELETE TASK");
    const oldLead = { ...lead };
    setIsLeadLoading(true);
    deleteTask(taskId)
      .then((data) => {
        setLead({
          ...lead,
          tasks: [...lead.tasks.filter((t) => t.id != taskId)],
        });
        toast.success("Tâche supprimée");
      })
      .catch((err) => setLead(oldLead))
      .finally(() => setIsLeadLoading(false));
  };

  const onUpdateComments = (comments) => {
    console.log("UPDATE COMMENTS");
    setLead({ ...lead, comments });
  };
  const onUpdateComment = (comment) => {
    console.log("UPDATE COMMENT");
    const oldLead = { ...lead };
    setLead({
      ...lead,
      comments: [
        ...lead.comments.map((c) => (c.id == comment.id ? comment : c)),
      ],
    });
    setIsLeadLoading(true);
    patchComment(comment.id, comment)
      .then((data) => {
        toast.success("Commentaire mis à jour");
        setLead(getFormattedLeadComments({ ...lead, comments: data.comments }));
      })
      .catch((err) => setLead(oldLead))
      .finally(() => setIsLeadLoading(false));
  };
  const onAddComment = (comment) => {
    console.log("ADD COMMENT", comment);

    const oldLead = { ...lead };
    setIsLeadLoading(true);
    postComment({
      entity: lead.id,
      entityRef: entityRefByCategory[productCategory],
      ...comment,
    })
      .then((data) => {
        toast.success("Commentaire ajouté");
        setLead((lead) =>
          getFormattedLeadComments({ ...lead, comments: data.comments })
        );
      })
      .catch((err) => {
        setLead(oldLead);
      })
      .finally(() => {
        setIsLeadLoading(false);
      });
  };
  const onDeleteComment = (commentId) => {
    console.log("DELETE COMMENT");
    const oldLead = { ...lead };
    setLead({
      ...lead,
      comments: [...lead.comments.filter((c) => c.id != commentId)],
    });
    setIsLeadLoading(true);
    deleteComment(commentId)
      .then((data) => {
        toast.success("Commentaire supprimé");
      })
      .catch((err) => setLead(oldLead))
      .finally(() => setIsLeadLoading(false));
  };

  const onUpdateAssignTo = (assignedTo, isForce = false) => {
    console.log("ASSIGN TO");
    setIsLeadLoading(true);
    const oldLead = { ...lead };
    setLead((l) => ({ ...l, assignedTo }));

    const urlByProductCategory = {
      PRO: `leads/${lead.id}/assigned-to`,
      DOMI: `quotes/${lead.id}/assigned-to`,
    };

    dataService.patch(
      urlByProductCategory[productCategory],
      { assignedTo: assignedTo || null, isForce },
      (data) => {
        setLead((l) => ({ ...lead, assignedTo, status: data.status }));
        toast.success(`Demande assignée ${isForce ? "(forçage)" : ""}`);
      },
      (err) => {
        if (err.assignedToExists) {
          if (
            window.confirm(
              `Cette demande est déjà assignée à \n${err.assignedToExists}, \nvoulez-vous tout de même la réassigner ?`
            )
          ) {
            onUpdateAssignTo(assignedTo, true);
          } else {
            setLead(oldLead);
          }
        } else {
          setLead(oldLead);
        }
      },
      () => setIsLeadLoading(false)
    );
  };
  const onUpdateStatus = (status, reason = {}) => {
    console.log("UPDATE STATUS");
    setIsLeadLoading(true);
    const oldLead = { ...lead };
    setLead({ ...lead, status, ...reason });

    const urlByProductCategory = {
      PRO: `leads/${lead.id}/status`,
      DOMI: `quotes/${lead.id}/status`,
    };
    dataService.patch(
      urlByProductCategory[productCategory],
      { status, ...reason },
      (data) => {
        setLead({
          ...lead,
          status: data.status,
          cancelReason: data.cancelReason || null,
          cancelText: data.cancelText || null,
          assignedTo: data.assignedTo,
          assignedAt: data.assignedAt,
          updatedAt: data.updatedAt,
        });
        toast.success("Statut de la demande modifié");
      },
      (err) => {
        setLead(oldLead);
      },
      () => setIsLeadLoading(false)
    );
  };

  const scrollLeadToTop = () => {
    const leadElem = document.querySelector(`#leaditem${lead.id}`);
    leadElem.scrollIntoView({ behavior: "smooth" });
  };

  const getLeadMRPFiles = async () => {
    return new Promise((resolve, reject) => {
      dataService.getMany(
        [
          {
            type: "GET",
            path: `quotations/${lead.quotationId}/files-by-file-type/PROPO_ASSU_MRP_PERCEVAL`,
            name: "MRP_PERCEVAL",
          },
          {
            type: "GET",
            path: `quotations/${lead.quotationId}/files-by-file-type/PROPO_ASSU_MRP_PC`,
            name: "MRP_PC",
          },
        ],
        (datas) => {
          resolve([datas.MRP_PERCEVAL, datas.MRP_PC]);
        },
        (err) => {}
      );
    });
  };
  const getLeadPJFiles = async () => {
    setIsSearchingFiles(true);
    return new Promise((resolve, reject) => {
      dataService.getMany(
        [
          {
            type: "GET",
            path: `quotations/${lead.quotationId}/files-by-file-type/PROPO_ASSU_PJ_PERCEVAL`,
            name: "PJ_PERCEVAL",
          },
        ],
        (datas) => {
          resolve([datas.PJ_PERCEVAL]);
        },
        (err) => {},
        () => setIsSearchingFiles(false)
      );
    });
  };
  const getLeadDOMIFiles = async () => {
    setIsSearchingFiles(true);
    return new Promise((resolve, reject) => {
      dataService.getMany(
        [
          {
            type: "GET",
            path: `quotes/${lead.id}/files`,
            name: "DOMI_PERCEVAL",
          },
        ],
        (datas) => {
          resolve(datas.DOMI_PERCEVAL);
        },
        (err) => {},
        () => setIsSearchingFiles(false)
      );
    });
  };

  return {
    lead,
    isLeadLoading,
    bottomPanelContent,
    isSearchingFiles,
    isFilesLoaded,
    actions: {
      initLead,
      openTasks,
      openComments,
      openInfos,
      closeBottomPanel,
      onUpdateTasks,
      onUpdateTask,
      onAddTask,
      onDeleteTask,
      onUpdateComments,
      onUpdateComment,
      onAddComment,
      onDeleteComment,
      onUpdateAssignTo,
      onUpdateStatus,
    },
  };
};

export default useLeadItem;
