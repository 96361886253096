import { atom, useAtom } from "jotai";
import dataService from "../../helpers/dataService";
import tools from "../../helpers/tools";
import { contactsAtom, managersAtom } from "./managersAtoms";
import { useEffect } from "react";

const isLoadingManagersAtom = atom(false);
const isLoadingContactsAtom = atom(false);

const useManagers = (fetchOnLoad) => {
  const [managers, setManagers] = useAtom(managersAtom);
  const [contacts, setContacts] = useAtom(contactsAtom);
  const [isLoadingManagers, setIsLoadingManagers] = useAtom(
    isLoadingManagersAtom
  );
  const [isLoadingContacts, setIsLoadingContacts] = useAtom(
    isLoadingContactsAtom
  );

  useEffect(() => {
    if (fetchOnLoad && !managers.length && !isLoadingManagers) {
      getManagers();
    }
    if (fetchOnLoad && !contacts.length && !isLoadingContacts) {
      getContacts();
    }
  }, []);

  const getManagers = () => {
    setIsLoadingManagers(true);
    dataService.get(
      `managers`,
      (data) => {
        setManagers(
          data.map((d) => ({ ...d, fullName: tools.getFullNameAbbr(d) }))
        );
      },
      (err) => {},
      () => setIsLoadingManagers(false)
    );
  };

  const getContacts = () => {
    setIsLoadingContacts(true);
    dataService.get(
      `managers/lister-mes-contacts`,
      (data) => {
        setContacts(
          data.map((d) => ({ ...d, fullName: tools.getFullNameAbbr(d) }))
        );
      },
      (err) => {},
      () => setIsLoadingContacts(false)
    );
  };

  return { getManagers, getContacts, managers, contacts };
};

export default useManagers;
