import { atom, useAtom } from "jotai";
import { DateTime } from "luxon";
import React from "react";
import { toast } from "react-toastify";
import { modalActions } from "../../../../../../context/actions/modalActions";
import useStore from "../../../../../../context/useStore";
import dataService from "../../../../../../helpers/dataService";
import { clientAtom } from "../useClientItem";
import UserForm from "./UserForm";

const isSendingActivationAtom = atom(false);
const isUnlockingAtom = atom(false);
const isActivatingAtom = atom(false);
const errorsAtom = atom({});
const isBlockUserAtom = atom(false);
const isBlockingAtom = atom(false);
const isAvisGoogleAtom = atom(false);

const useUserInfos = () => {
  const [client, setClient] = useAtom(clientAtom);
  const [isSendingActivation, setIsSendingActivation] = useAtom(
    isSendingActivationAtom
  );
  const [isActivating, setIsActivating] = useAtom(isActivatingAtom);
  const [isUnlocking, setIsUnlocking] = useAtom(isUnlockingAtom);
  const [isSendingAvisGoogle, setIsAvisGoogle] = useAtom(isAvisGoogleAtom);
  const [isBlocking, setIsBlocking] = useAtom(isBlockingAtom);
  const [errors, setErrors] = useAtom(errorsAtom);
  const [isBlockUser, setIsBlockUser] = useAtom(isBlockUserAtom);

  const [state, dispatch] = useStore();
  const mActions = modalActions(state, dispatch);

  const sendActivation = () => {
    setIsSendingActivation(true);
    dataService.get(
      `customers/${client.id}/email_activation`,
      (data) => {
        setClient({
          ...client,
          activationTokenSendedAt: DateTime.local().toISO(),
        });
        toast.success("Email d'activation envoyé");
      },
      (err) => {},
      () => setIsSendingActivation(false)
    );
  };

  const sendAvisGoogle = () => {
    setIsAvisGoogle(true);
    dataService.get(
      `customers/${client.id}/avis-google`,
      (data) => {
        setClient({
          ...client,
          emailAvisGoogleSentAt: DateTime.local().toISO(),
        });
        toast.success("Email avis Google envoyé");
      },
      (err) => {},
      () => setIsAvisGoogle(false)
    );
  };

  const unlockAccount = () => {
    setIsUnlocking(true);
    dataService.get(
      `customers/${client.id}/authorization/reset`,
      (data) => {
        toast.success("Le mot de passe de l'utilsateur a été réinitialisé");
      },
      setErrors,
      () => setIsUnlocking(false)
    );
  };

  const activateMail = () => {
    setIsActivating(true);
    dataService.patch(
      `customers/${client.id}`,
      { ...client, isActivated: true },
      (data) => {
        toast.success("Le compte de l'utilisateur a bien été activé");
      },
      setErrors,
      () => setIsActivating(false)
    );
  };

  const blockUserAccount = () => {
    if (
      window.confirm(
        `Souhaitez-vous ${
          isBlockUser ? "débloquer" : "bloquer"
        } cet utilisateur ?`
      )
    ) {
      setIsBlocking(true);
      if (isBlockUser) {
        setIsBlockUser(false);
        dataService.patch(
          `customers/${client.id}/update-lock-status`,
          {},
          toast.success(`Le compte de l'utilisateur a bien été débloquer`),

          setErrors
        );
      } else {
        setIsBlockUser(true);
        dataService.patch(
          `customers/${client.id}/update-lock-status`,
          {},
          toast.success(`Le compte de l'utilisateur a bien été bloquer`),

          setErrors
        );
      }
      setIsBlocking(false);
    }
  };

  const updateClient = (newClient) => {
    setClient(newClient);
  };

  const openUserForm = () => {
    mActions.updateModal({
      isOpen: true,
      content: <UserForm client={client} updateClient={updateClient} />,
    });
  };

  return {
    client,
    isSendingActivation,
    isSendingAvisGoogle,
    errors,
    isActivating,
    isUnlocking,
    isBlockUser,
    isBlocking,
    actions: {
      sendActivation,
      sendAvisGoogle,
      unlockAccount,
      activateMail,
      updateClient,
      openUserForm,
      blockUserAccount,
    },
  };
};

export default useUserInfos;
